<template>
  <div class="teacher-container">
    <!-- 师资介绍 - start  -->
    <div class="index-teacher">
      <!-- 标题 -->
      <div class="index-teacher-title">
        <div class="title_ch">师资力量</div>
        <div class="title_en">TEACHERS FACULTY</div>
      </div>
      
      <!-- 副标题 -->
      <div class="index-teacher-sub-title">
        <div class="index-teacher-sub-title-1">
          <span style="font-size: 40px;color: #1C70FF;">10</span><span style="font-size: 20px;color: #1C70FF;">余位</span>
          <span style="font-size: 20px;color: #666; padding: 0 6px;">技术总监、架构师级大咖讲师</span>
        </div>

        <div class="index-teacher-sub-title-2">
          <span style="font-size: 20px;color: #666; padding: 0 4px;">平均</span>
          <span style="font-size: 40px;color: #1C70FF;">10</span><span style="font-size: 20px;color: #1C70FF;">年以上</span>
          <span style="font-size: 20px;color: #666; padding: 0 6px;">研发&授课经验</span>
        </div>
      </div>

      <!-- 师资介绍 狮子展示 - start  -->
      <div class="index-teacher-list">
        <div class="index-teacher-list-item" v-for="(teacher) in teacherList" :key="teacher.id">
          <img :src="`${$apiBaseUrl}${teacher.avatar}`" />
          <!-- 教师描述 start -->
          <div class="index-teacher-list-item-desc" >
            <div class="teacher-name">
              <span v-text="teacher.name"
                style="font-size: 16px; font-weight: bold; margin-right: 5px"
                ></span
              >
              <span v-text="teacher.level" style="font-size: 12px"></span>
            </div>
            <div class="teacher-feature">
              <span v-for="(fea,idx) in teacher.feature"  :key="teacher.id+'-'+idx" v-text="fea" ></span>
            </div>
            <div class="teacher-details" :title="teacher.intro">
              <x-short-show :max-length="52" :content="teacher.intro" />
              
            </div>
          </div>
          <!-- 教师描述 end -->
        </div>
      </div>

      <!-- 师资介绍 狮子展示 - end  -->
    </div>
    <!-- 师资介绍 - end  -->
  </div>
</template>

<script>
import XShortShow from '@/components/XShortShow.vue'


const teacherList = [];//狮子列表
const getTeaherList = async function(){
  const result = await this.$httpGet('/front/teacher/list');
  this.teacherList = result.data.map(t=>{
    const arr = t.feature && t.feature.split(",");
    return {...t,feature:(arr instanceof Array ? arr:[arr])};  
  });
  
  this.teacherList.sort(() => Math.random() - 0.5);
}

export default {
  components: {
   XShortShow
  },
  data() {
    return {      
      teacherList,
    };
  },
  created() {
    this.getTeaherList();
  },
  mounted() {},
  methods: {
    getTeaherList
  },
};
</script>

<style scoped >
@import url(./IndexTeacher.css);
</style>

